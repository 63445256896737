import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";

export const FormInputText = ({ name, control, label, isMultiLine, isRequired }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          required={isRequired}
          value={value}
          multiline={isMultiLine}
          fullWidth
          label={label}
          variant="standard"
        />
      )}
    />
  );
};
