
import { useForm } from "react-hook-form";
import { FormInputText } from "./form-components/FormInputText";
import { FormInputDropdown } from "./form-components/FormInputDropdown";
import { FormInputMultiDropdown } from "./form-components/FormInputMultiDropdown";
import { FormInputSlider } from "./form-components/FormInputSlider";

import React from "react";
import Paper from "@mui/material/Paper/Paper";
import { Button, Typography } from "@mui/material";

export const IntakeForm = () => {
  const methods = useForm();

  const { handleSubmit, reset, control, setValue } = methods;

  const onSubmit = (data: any) => {
    let message = '';

    for (var key in data) {
      if (data[key] !== ('' || [''])) {

        if (Array.isArray(data[key]) && data[key].length > 0) {
          message += key + ": " + data[key].join(",");
          message += "<br /><br />";
        }
        else if (data[key].length > 0) {
          message += key + ": " + data[key];
          message += "<br /><br />";
        }
      }
    }

    let postData = {
      from: "info@digitalgrounds.ca",
      to: "info@digitalgrounds.ca",
      subject: "New App Intake Form Submission",
      message: message,
      info: "fs8934jLFJLIEii79*!#U"
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    };

    fetch('https://portal.digitalgrounds.ca/email/email.php', requestOptions)
      .then(() => {
        alert("Your information has been submitted.")
      })
  };

  return (
    <Paper
      style={{
        display: "grid",
        gridRowGap: "20px",
        padding: "20px",
        margin: "10px 300px",
      }}
    >
      <Typography variant="h6">Form</Typography>

      <FormInputText name="Email" isRequired={true} control={control} label="Email" />


      <FormInputDropdown
        name="Service Requested"
        isRequired={true}
        control={control}
        options={[
          {
            label: "New app",
            value: "1",
          },
          {
            label: "Changes to existing app",
            value: "2",
          },
        ]}
        label="Service Requested"
      />



      <FormInputText name="Project Name" isRequired={true} control={control} label="Project Name" />



      <FormInputMultiDropdown
        control={control}
        options={[
          {
            label: "E-commerce/Payment Processing",
            value: "1",
          },
          {
            label: "QR Code/Bar Code",
            value: "2",
          },
          {
            label: "Notifications",
            value: "3",
          },
          {
            label: "Mapping/GPS",
            value: "4",
          },
          {
            label: "Ratings/Reviews",
            value: "5",
          },
          {
            label: "AI",
            value: "6",
          },
          {
            label: "Feedback/Comments",
            value: "7",
          },
          {
            label: "News/Bulletins",
            value: "8",
          },
          {
            label: "Authentication/Identity/Account Management",
            value: "9",
          },
          {
            label: "Data Reports",
            value: "10",
          },
          {
            label: "Content Management",
            value: "11",
          },
          {
            label: "Device/Hardware Integration",
            value: "12",
          },
          {
            label: "Sign In with Social Media",
            value: "13",
          },
          {
            label: "Family/Parental Controls",
            value: "14",
          },
          {
            label: "Other",
            value: "15",
          },

        ]}
        name={"Features"}
        label={"Features"}
      />

      <FormInputMultiDropdown
        name="Intended Use"
        control={control}
        options={[
          {
            label: "Sales/Revenue generation",
            value: "1",
          },
          {
            label: "Internal corporate use",
            value: "2",
          },
          {
            label: "Other",
            value: "3",
          },
        ]}
        label="Intended Use"
      />

      <FormInputText name="Project Description" isMultiLine={true} isRequired={true} control={control} label="Project Description" />

      <FormInputDropdown
        name="Timeline"
        control={control}
        options={[
          {
            label: "ASAP / Urgent",
            value: "1",
          },
          {
            label: "Within 6 months",
            value: "2",
          },
          {
            label: "6 - 12 Months",
            value: "3",
          },
        ]}
        label="Timeline"
      />

      <FormInputSlider
        name={"Readiness level"}
        control={control}
        setValue={setValue}
        label={"Readiness level"}
      />

      <FormInputDropdown
        name="Entity"
        control={control}
        options={[
          {
            label: "Corporation",
            value: "1",
          },
          {
            label: "Sole proprietor",
            value: "2",
          },
          {
            label: "Individual",
            value: "3",
          },
        ]}
        label="Entity"
      />

      <Typography variant="caption">You will hear from one of our experts within 3-5 business days</Typography>

      <Button onClick={handleSubmit(onSubmit)} variant={"contained"}>
        {" "}
        Submit{" "}
      </Button>
      <Button onClick={() => reset()} variant={"outlined"}>
        {" "}
        Reset{" "}
      </Button>
    </Paper>
  );
};